html {
  height: 100%;
  font-size: 62.5%;
}
body {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  font-size: tovw(18);
  color: #fff;
  font-family: $roboto;
  background-color: #000;
  &.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    height: 100% !important;
  }
}
img {
  max-width: 100% !important;
}
#root {
  position: relative;
  min-height: 100%;
}
button {
  outline: none !important;
}
a {
  color: #fff;
  transition: color 0.25s ease;
  outline: none !important;
  &:hover {
    color: #000;
    text-decoration: none;
  }
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 1rem;
  padding-right: 1rem;
}
.container {
  max-width: 100% !important;
  padding-left: 1rem;
  padding-right: 1rem;
  &--content {
    max-width: tovw(1500) !important;
  }
}
.row {
  margin-left: -1rem;
  margin-right: -1rem;
}
.swal2-shown,
.ReactModal__Body--open {
  #root {
    //filter: blur(5px);
  }
}
.swal2-container.swal2-backdrop-show {
  background-color: rgba(0, 0, 0, 0.6) !important;
  overflow-y: auto !important;
  .swal2-popup {
    display: flex;
  }
}
.swal2-container {
  .swal2-popup {
    height: auto !important;
    width: tovw(650) !important;
    min-height: tovw(430) !important;
    background: url(/images/swal.png) !important;
    background-size: 100% 100% !important;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .swal2-close,
    .close {
      position: absolute;
      width: 14px;
      height: 14px;
      // background: url(#{$cdnUrl}/images/close.png) no-repeat center;
      background-size: 100%;
      top: 10px;
      right: 10px;
      opacity: 1;
      font-size: 4rem;
      outline: none !important;
      color: #000 !important;
      font-family: serif;
      font-weight: 300;
      z-index: 9;
      text-indent: -9999px;
    }
    #swal2-content {
      font-size: tovw(20);
      color: #f3e1b9;
      line-height: 150%;
      text-transform: uppercase;
      p {
        font-size: tovw(20);
        color: #fff;
        line-height: 150%;
        text-transform: uppercase;
      }
    }
    #swal2-title,
    h2 {
      font-size: tovw(68);
      font-weight: normal;
      text-align: left;
      text-transform: uppercase;
      margin-left: auto;
      margin-right: auto;
      margin-top: tovw(30);
      padding-left: 1rem;
      padding-right: 1rem;
      background: #D3A5FF;
      background: linear-gradient(to right, #D3A5FF 0%, #FFA6C5 28%, #D7F3B3 50%, #BDF7FF 64%, #E1B4F0 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      justify-content: center;
      font-family: $font-heading;
      text-shadow: tovw(0.877) tovw(1.798) 0 rgba(1, 0, 22, 0.2),-1px 0px 0px rgba(80, 241, 251, 0.2);
    }
    &.popup-image {
      background: transparent !important;
      box-shadow: none !important;
      width: auto !important;
      padding: 0 !important;
      border-radius: 0.5vw !important;
      overflow: hidden;
      img {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
        backface-visibility: hidden;
      }
    }
  }
}
.ReactModal__Overlay {
  display: flex !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0;
  left: 0 !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 10px !important;
  background: rgba(0, 0, 0, 0.8) !important;
  z-index: 1060 !important;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch !important;
}
.ReactModal__Content {
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: inherit;
  box-sizing: border-box;
  margin: auto;
  width: tovw(568) !important;
  min-height: tovw(300) !important;
  background: url(/images/popup.png) no-repeat !important;
  background-size: 100% 100% !important;
  border: 0 !important;
  padding: 2rem 0 !important;
  text-align: center;
  outline: none;
  .modal-description {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  &.modal-info {
    width: tovw(930) !important;
    min-height: tovw(639) !important;
    .modal-description {
      width: 92%;
      padding-right: tovw(94);
    }
  }
  &.modal-history {
    width: tovw(1207) !important;
    min-height: tovw(735) !important;
    .modal-description {
      width: 92%;
      padding-right: tovw(94);
    }
  }
  &.modal-video {
    width: tovw(1149) !important;
    min-height: tovw(800) !important;
    .modal-description {
      width: 92%;
      padding-right: tovw(94);
    }
    h2 {
      margin-bottom: tovw(0);
      padding: 0;
    }
    p {
      text-align: left;
      margin-bottom: tovw(15);
    }
  }
  .close {
    position: absolute;
    width: 14px;
    height: 14px;
    // background: url(#{$cdnUrl}/images/close.png) no-repeat center;
    background-size: 100%;
    top: 10px;
    right: 10px;
    opacity: 1;
    font-size: 4rem;
    outline: none !important;
    color: #000 !important;
    font-family: serif;
    font-weight: 300;
    z-index: 9;
    text-indent: -9999px;
  }
  h2 {
    font-size: tovw(68);
    font-weight: normal;
    text-align: left;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    margin-top: tovw(30);
    padding-left: 1rem;
    padding-right: 1rem;
    background: #D3A5FF;
    background: linear-gradient(to right, #D3A5FF 0%, #FFA6C5 28%, #D7F3B3 50%, #BDF7FF 64%, #E1B4F0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    justify-content: center;
    font-family: $font-heading;
    text-shadow: tovw(0.877) tovw(1.798) 0 rgba(1, 0, 22, 0.2),-1px 0px 0px rgba(80, 241, 251, 0.2);
  }
  p,
  li {
    font-size: tovw(20);
    color: #fff;
  }
}
#main-body {
  position: relative;
}
.main-content {
  position: relative;
}
.ScrollbarsCustom-Wrapper {
  right: 0 !important;
}
.ScrollbarsCustom-Track {
  width: tovw(10) !important;
  right: tovw(-35) !important;
  z-index: 1;
  background: transparent !important;
  &:before {
    content: "";
    position: absolute;
    width: 1px;
    background: #bad2f4;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }
  .modal-history & {
    right: tovw(-20) !important;
    &:before {
      background: #bad2f4;
    }
  }
}
.ScrollbarsCustom-Thumb {
  width: 100% !important;
  border-radius: 0.5vw !important;
  background: #bad2f4 !important;
  .modal-history & {
    background: #bad2f4 !important;
  }
}
#header {
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
#footer {
  position: relative;
  background: #000;
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
  .container {
    width: tovw(1320);
  }
  .footer-logo {
    display: inline-block;
    width: tovw(226);
  }
  .age {
    display: inline-block;
    width: tovw(142);
  }
  .col:nth-child(2) {
    flex: 0 0 tovw(910);
    max-width: tovw(910);
  }
  p {
    font-size: tovw(11);
    text-transform: uppercase;
    color: #6a6a6a;
    text-align: justify;
    margin-bottom: 0;
  }
}
.top-bar {
  position: relative;
  height: tovw(73);
  background: url(/images/bg-header.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  > .container {
    height: 100%;
    max-width: tovw(1620) !important;
    margin-left: auto;
    margin-right: auto;
    > .row {
      height: 100%;
    }
  }
  .col-7 {
    text-align: right;
  }
  span {
    display: inline-block;
    color: #fff;
    font-size: tovw(18);
    em {
      width: 1px;
      height: 1vw;
      background: #fff;
      display: inline-block;
      vertical-align: middle;
      margin-left: 0.5vw;
      margin-right: 0.5vw;
    }
    .text-red {
      font-size: tovw(15);
      width: tovw(26);
      height: tovw(26);
      padding-top: 1px;
      background-color: #f71f2b;
      border-radius: 50%;
      border: 1px solid #fff;
      text-align: center;
      color: #fff !important;
    }
  }
}
.nickname {
  display: inline-block;
  max-width: 8vw;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: tovw(-3);
  margin-right: tovw(30);
}
.logo {
  display: inline-block;
  height: tovw(47);
  margin-top: tovw(8);
  img {
    display: block;
    backface-visibility: hidden;
    height: 100%;
  }
}
.menu-link {
  display: inline-block;
  font-size: tovw(22);
  text-transform: uppercase;
  color: #fff;
  position: relative;
  line-height: tovw(73);
  background-color: transparent;
  font-family: $font-primary;
  font-weight: 300;
  padding: 0 tovw(22);
  z-index: 1;
  transition: font-weight .3s ease;
  &:hover {
    color: #fff;
    font-weight: bold;
  }
}
.btn-login {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: tovw(-120);
  transform: translateY(-50%);
  width: tovw(131);
  height: tovw(41);
  line-height: tovw(41);
  font-size: tovw(18);
  font-weight: bold;
  text-transform: uppercase;
  vertical-align: middle;
  margin-left: 0.5vw;
  text-align: center;
  color: #0b00cf;
  transition: color .3s ease;
  &:hover {
    color: #0b00cf;
    text-decoration: underline;
  }
}
.btn-logout {
  position: absolute;
  top: 50%;
  right: tovw(-110);
  transform: translateY(-50%);
  padding: 0.5vw;
  font-size: tovw(18);
  font-weight: bold;
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
  color: #0b00cf;
  transition: color .3s ease;
  &:hover {
    color: #0b00cf;
  }
}
.wrap-video {
  width: 100%;
  position: relative;
  z-index: 1;
  video {
    width: 100%;
    display: block;
    // display: none;
    @include max-screen(1200px) {
      display: none;
    }
  }
  img {
    width: 100%;
    display: block;
    @include min-screen(1200px) {
      display: none;
    }
  }
  .play-now {
    position: absolute;
    bottom: tovw(150);
    left: tovw(495);
    z-index: 10;
    transition: all .3s ease;
    transform-origin: center center;
    animation: bounce 1s infinite linear;
    img {
      width: tovw(276);
      display: inline-block !important;
    }
    &:hover {
      transform: scale(0.95);
    }
  }
}
.section {
  position: relative;
  &--2 {
    padding-top: tovw(105);
    padding-bottom: tovw(55);
    min-height: tovw(1700);
    background: url(/images/section2.jpg) top center no-repeat;
    background-size: 100% 100%;
  }
  &--3 {
    padding-top: tovw(86);
    padding-bottom: tovw(60);
    background: url(/images/bg-section3.jpg) top center no-repeat;
    background-size: 100% 100%;
  }
}
.sub-title {
  font-size: tovw(18);
  color: #fff;
}
.spin {
  .col-9 {
    flex: 0 0 78%;
    max-width: 78%;
  }
  .col-3 {
    flex: 0 0 22%;
    max-width: 22%;
  }
  .title {
    text-align: right;
    margin-top: tovw(20);
    margin-bottom: tovw(200);
    position: relative;
    &::before{
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      margin-right: tovw(-35);
      width: tovw(380);
      height: tovw(200);
      transform: translateY(-50%);
      background: url(/images/title-upgrate-shadow.png) no-repeat;
      background-size: 100% 100%;
    }
    img {
      width: tovw(255);
      position: relative;
      z-index: 2;
    }
  }
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 tovw(50);
  }
  &__item {
    width: tovw(182);
    height: tovw(185);
    position: relative;
    background: url(/images/bg-item-up.png) no-repeat;
    background-size: 100% 100%;
    margin-bottom: tovw(140);
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: tovw(4) solid #30ffff;
      opacity: 0;
      visibility: hidden;
    }
    &--active {
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
    img {
      position: absolute;
      bottom: tovw(20);
      left: 50%;
      transform: translateX(-50%);
      height: tovw(135);
    }
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10) {
      background: url(/images/bg-item-down.png) no-repeat;
      background-size: 100% 100%;
      margin-bottom: 0;
    }
  }
  &__level {
    display: flex;
    margin-left: tovw(20);
    margin-top: tovw(15);
    justify-content: center;
    &--max {
      .spin__level--label {
        font-size: 0;
      }
      .spin__level--list {
        span,
        .active {
          background-color: #a91922;
          border: 1px solid #a91922;
        }
      }
    }
    &--label {
      font-size: tovw(14);
      color: #fff;
      text-transform: uppercase;
      margin-right: tovw(6);
      font-family: $acumin;
      line-height: 1;
      margin-top: tovw(4);
    }
    &--list {
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        width: tovw(20);
        height: tovw(10);
        border: 1px solid #30ffff;
        background-color: #000b58;
        margin-right: tovw(5);
        &.active {
          background-color: #30ffff;
        }
      }
    }
  }
  &__actions {
    position: absolute;
    top: tovw(333);
    left: 0;
    width: 100%;
    padding-left: tovw(606);
    a {
      display: inline-block;
      width: tovw(215);
      height: tovw(54);
      background: url(/images/btn-spin1.png) no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: tovw(24);
      text-transform: uppercase;
      text-align: center;
      line-height: tovw(53);
      margin-right: tovw(30);
      transition: transform .3s ease;
      font-family: $svn;
      &:hover {
        transform: translateY(-1rem);
      }
      &:nth-child(2) {
        background: url(/images/btn-spin2.png) no-repeat;
        background-size: 100% 100%;
      }
      &:nth-child(3) {
        background: url(/images/btn-spin3.png) no-repeat;
        background-size: 100% 100%;
      }
      &:nth-child(4) {
        color: #09449b;
        background: url(/images/btn-spin4.png) no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
.accumulate {
  padding-top: tovw(370);
  .title {
    margin-bottom: tovw(10);
    &::before {
      width: tovw(1568);
      height: tovw(137);
      background: url(/images/title-medal-shadow.png) no-repeat;
      background-size: 100% 100%;
    }
    img {
      width: tovw(553);
    }
  }
  &__medal {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: tovw(20);
    padding-bottom: tovw(17);
    margin-bottom: tovw(30);
    position: relative;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 96%;
      height: 1px;
      background-color: #224186;
    }
    &--item {
      display: flex;
      align-items: center;
      margin-right: tovw(35);
      &:last-child {
        margin-right: 0;
      }
      img {
        height: tovw(63);
      }
    }
    &--img {
      margin-right: tovw(10);
    }
    &--total {
      font-size: tovw(20);
    }
  }
  &__rewards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--item {
      width: tovw(260);
      text-align: center;
    }
    &--img {
      margin-bottom: tovw(10);
      img {
        height: tovw(220);
      }
    }
    &--btn {
      width: 100%;
      height: tovw(50);
      background: url(/images/btn-medal.png) no-repeat;
      background-size: 100% 100%;
      font-size: tovw(20);
      color: #18213d;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      cursor: pointer;
      transition: transform .3s ease;
      &:hover {
        transform: translateY(-1rem);
      }
      img {
        height: tovw(40);
        margin-left: tovw(8);
      }
    }
  }
}
.missions {
  padding-top: tovw(96);
  padding-bottom: tovw(110);
  width: 91%;
  margin-left: auto;
  margin-right: auto;
  background: url(/images/bg-mission.png) top center no-repeat;
  background-size: 100% 100%;
  .title {
    margin-bottom: tovw(15);
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-right: tovw(-35);
      width: tovw(818);
      height: tovw(253);
      background: url(/images/title-friend-shadow.png) no-repeat;
      background-size: 100% 100%;
    }
    img {
      width: tovw(475);
      position: relative;
      z-index: 2;
    }
  }
  .sub-title {
    margin-bottom: tovw(40);
    position: relative;
    z-index: 2;
  }
  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 tovw(110);
  }
  &__item {
    width: tovw(205);
    text-align: center;
    &--img {
      margin-bottom: tovw(17);
      position: relative;
      img {
        width: 100%;
      }
      span {
        position: absolute;
        top: tovw(-8);
        right: tovw(-19);
        img {
          height: tovw(95);
        }
      }
    }
    p {
      text-transform: uppercase;
      font-size: tovw(20);
      margin-bottom: tovw(20);
    }
    &--btn {
      width: 100%;
      height: tovw(50);
      background: url(/images/btn-gradient.png) no-repeat;
      background-size: 100% 100%;
      font-size: tovw(20);
      color: #18213d;
      text-transform: uppercase;
      text-align: center;
      line-height: tovw(50);
      cursor: pointer;
      transition: transform .3s ease;
      &:hover {
        transform: translateY(-1rem);
      }
      &.done {
        background: url(/images/btn-blue.png) no-repeat;
        background-size: 100% 100%;
        color: #fff;
      }
    }
  }
  &__btn-update {
    display: inline-block;
    width: tovw(280);
    height: tovw(60);
    background: url(/images/btn-medal.png) no-repeat;
    background-size: 100% 100%;
    font-size: tovw(26);
    text-transform: uppercase;
    line-height: tovw(65);
    color: #18213d;
    text-align: center;
    font-family: $acumin;
    position: absolute;
    bottom: tovw(-80);
    left: 50%;
    transform: translateX(-50%);
    transition: transform .3s ease;
    &:hover {
      transform: translateY(-1rem) translateX(-50%);
    }
  }
}
.videos {
  padding-top: tovw(54);
  .title {
    margin-bottom: tovw(20);
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: tovw(-30);
      width: tovw(1334);
      height: tovw(141);
      background: url(/images/title-video-shadow.png) no-repeat;
      background-size: 100% 100%;
    }
    img {
      width: tovw(780);
      position: relative;
      z-index: 2;
    }
  }
  .sub-title {
    color: #000b58;
    margin-bottom: tovw(40);
  }
}
.video {
  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: tovw(35);
    .swiper-next-video {
      right: 0 !important;
      // margin-top: tovw(-80) !important;
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: tovw(36) !important;
      height: tovw(81) !important;
      background: url(/images/icon-next.png) no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 50%;
      margin-top: tovw(-40);
      &::after {
        display: none;
      }
    }
    .swiper-prev-video {
      left: 0 !important;
      // margin-top: tovw(-80) !important;
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: tovw(36) !important;
      height: tovw(81) !important;
      background: url(/images/icon-prev.png) no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 50%;
      margin-top: tovw(-40);
      &::after {
        display: none;
      }
    }
  }
  &__item {
    padding: 0 tovw(66);
    margin-bottom: tovw(45);
    cursor: pointer;
  }
  &__player {
    height: tovw(186);
    margin-bottom: tovw(15);
    border: 2px solid #fff;
    box-sizing: content-box;
    img {
      height: tovw(186);
      width: 100%;
      object-fit: cover;
    }
  }
  &__name {
    font-size: tovw(20);
    color: #000b58;
    // font-family: $font-heading;
    margin-bottom: tovw(8);
    max-width: 18.75vw;
    overflow: hidden;
    vertical-align: middle;
    text-overflow: ellipsis;
    font-weight: bold;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
  }
  &__content {
    font-size: tovw(18);
    color: #000b58;
    max-width: 18.75vw;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-align: center;
  }
}
.swiper-button-disabled {
  opacity: 0.3;
}
.modal-video {
  &__wrap {
    width: tovw(955);
    height: tovw(565);
    margin: 0 auto;
  }
}
.item-result {
  img {
    height: tovw(250);
  }
}
.match-progress {
  text-align: left;
  p {
    font-weight: normal;
    text-transform: none !important;
    color: #fff !important;
    font-size: tovw(20);
    text-transform: uppercase !important;
    line-height: 1.1;
    margin-bottom: 0;
  }
  img {
    display: inline-block;
    height: tovw(40);
    backface-visibility: hidden;
  }
  .play-progress-share {
    margin-left: 1px;
  }
  .w-100 {
    margin-bottom: 2rem;
  }
  .col-5 {
    text-align: right;
  }
}
.table-history {
  width: 100%;
  tbody {
    tr {
      border-top: 1px solid #516b84;
    }
  }
  tr {
    th,
    td {
      font-size: tovw(20);
      color: #fff;
      padding: tovw(15) 0;
      font-family: $roboto;
      font-weight: 300;
    }
    th {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
.popup-info {
  &__title {
    margin-bottom: tovw(14);
    padding-left: tovw(30);
  }

  &__desc {
    font-size: tovw(20);
    color: $white;
    margin-bottom: tovw(16);
    text-align: left;
    padding-left: tovw(40);
  }

  &__form {
    .form-group {
      margin: 0 auto;
      width: tovw(678);
      position: relative;
      > div {
        position: relative;
      }
      & ~ .form-group {
        margin-top: tovw(20);
      }

      label {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        white-space: nowrap;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: tovw(18);
        padding-left: tovw(27);
        font-weight: normal;
        font-style: italic;
      }

      input {
        width: 100%;
        height: tovw(52);
        display: block;
        background: #051133;
        border: tovw(2) solid #24848a;
        padding: 0 tovw(20) 0 0;
        border-radius: 0;
        font-size: tovw(18);
        color: #fff;
        outline: none;
        &:focus {
          outline: none;
        }
      }

      &:nth-child(1) {
        input {
          padding-left: tovw(220);
        }
      }
      &:nth-child(2) {
        input {
          padding-left: tovw(180);
        }
      }
      &:nth-child(3) {
        input {
          padding-left: tovw(250);
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: tovw(20);

    button ~ button {
      margin-left: tovw(15);
    }
    .btn-secondary {
      background: url(/images/btn-reset.png) no-repeat !important;
      background-size: 100% 100% !important;
      font-size: 0 !important;
    }
  }
  &__note {
    text-align: left;
    padding-left: tovw(43);
    margin-top: tovw(20);
    font-style: italic;
  }
}
.text-error {
  margin: 0 auto;
  width: tovw(620);
}
.prizes {
  opacity: 1 !important;
  width: px(490) !important;
  // height: px(800) !important;
  // padding: px(20) px(20) 0 !important;
  // box-shadow: none !important;
  // border: px(3) solid $colorPurple !important;
  background: transparent !important;
  img {
    width: 100% !important;
  }

  &:after {
    display: none !important;
  }
}
